<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3604 0L12.7551 1.84431L15.7668 1.58746L16.7842 4.43368L19.4567 5.84585L18.7738 8.79032L20.2586 11.4231L18.0922 13.531L17.9178 16.5486L14.9558 17.1507L13.1777 19.5949L10.3604 18.5L7.54303 19.5949L5.7649 17.1507L2.80286 16.5486L2.62848 13.531L0.462137 11.4231L1.94687 8.79032L1.26403 5.84585L3.93648 4.43368L4.95394 1.58746L7.96562 1.84431L10.3604 0Z"
      fill="#003DFF"
    />
    <g clip-path="url(#clip0_17885_7979)">
      <path
        d="M14.0674 6.29298C14.0674 6.29298 15.6401 9.85831 11.4984 14C11.4984 14 11.401 12.0083 9.8765 10.4838C8.35206 8.95935 6.36035 8.86201 6.36035 8.86201C10.502 4.72038 14.0674 6.29298 14.0674 6.29298Z"
        fill="white"
      />
      <path
        d="M6.96413 13.3957C6.96413 13.3957 8.51153 13.6558 9.34679 12.8206C9.84591 12.3214 9.84591 11.5121 9.34679 11.013C8.84768 10.5139 8.03835 10.5139 7.53924 11.013C6.7039 11.8483 6.96413 13.3957 6.96413 13.3957Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_17885_7979">
        <rect
          width="8"
          height="8"
          fill="white"
          transform="translate(6.36035 6)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
